import React from "react";
import Seo from "../../../components/seo";
import Industry from "../../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../../components/layout";
import {Factories} from '../../../components/svgs/industries';

const FactoriesIndustryPageEn = () => {
  const data = useStaticQuery(graphql`
    query FactoriesIndustryDatasEn {
      allContentfulSecteurs(filter: {contentful_id: {eq: "626he1WN2uKETazng2SuCd"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[0].node.permalink} 
    lang="en-CA"
    title={data.allContentfulSecteurs.edges[1].node.name} 
    subTitle="Digital signage in"
    icon={<Factories/>}>
      <Seo title="Digital Signage Communications for Factories"
           description="A digital signage communication system helps to broadcast information effectively with your employees, no matter your business industry. Attractif, a digital signage employee engagement system customized to your needs."
           lang="en-CA"/>
      <Industry lang="en-CA" datas={data.allContentfulSecteurs.edges[1].node}/>
    </Layout>
  )
}

export default FactoriesIndustryPageEn
